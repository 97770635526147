'use strict'

###*
 # @ngdoc service
 # @name lpa.lpaIncidentTracking.factory:ProposalManager
 # dispatchUnit	choice	true
 # incident	choice	false
 # taskType	choice	true
 # externallyConfirmed	boolean	true
 # closedAt	datetime	true	yyyy-MM-dd'T'HH:mm:ssZZZZZ
 # tenant	choice	true	[{"__initializer__":null,"__cloner__":null,"__isInitialized__":true,"versionable":true}]
 # @description

###

class ProposalManagerFactory
  ### @ngInject ###
  constructor: (
    $log
    Tasks
    TaskTypes
  ) ->
    ProposalManagerBase = {}
    ProposalManagerBase.debug = (proposal) ->
      $log.debug proposal

    # PRIVATE
    ProposalManagerBase.getIncidentTaskType = () ->
      # Apply the filter on the GET
      params =
        'filter[]': [
          "code,INCIDENT"
          ]
      TaskTypes.getList(params)
      .then (taskType) ->
        return taskType

    ProposalManagerBase.createTask = (proposal, taskType) ->
      task =
        dispatchUnit: proposal.dispatchUnit.id
        incident: proposal.incident.id
        taskType: taskType.id
        externallyConfirmed: false
        label: proposal.incident.id

      return Tasks.post task, null,
        autoTenant: true

    return ProposalManagerBase

angular
  .module 'lpaIncidentTracking'
  .factory 'ProposalManager', ProposalManagerFactory
